import React from 'react'
import { Link } from "gatsby"
import CTA from "./cta"
import { Location } from '@reach/router'

const PageFooter = () => (
    <Location>
        {({ location }) => {

            return (
                <footer className="pageFooter">

                    <CTA
                        classNames="cta--topLink"
                        to={`${location.pathname}#top`}
                        text="top"
                        title="Click here to scroll to the top!"
                        hideText
                    />

                    <ul className="pageFooter__links">
                        <li>
                            <Link className="pageFooter__link" to="/legal_notice">Legal notice</Link>
                        </li>
                        <li>
                            <Link className="pageFooter__link" to="/privacy_statement">Privacy statement</Link>
                        </li>
                        <li>
                            <Link className="pageFooter__link" to="/changelog">Changelog</Link>
                        </li>
                    </ul>


                </footer>
            );
        }}
    </Location>
)

export default PageFooter