import React from 'react'
import { Link } from "gatsby"
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import SIGNET from '../../img/chrome/mood-design-signet-anim.svg'

const Logo = props => {
    const { placement } = props;
    let linkClassNames = placement ? `logo logo--${placement}` : 'logo';

    return (
        <Link to="/" className={linkClassNames}>
            <SVG src={SIGNET} className="signet" viewBox="0 0 167 72" />{/*0 0 167 127*/}
            <SVG src={SIGNET} className="signet-small" viewBox="45.5 0 72 72" />
        </Link >
    )
}

export default Logo