import React from 'react'
import { Link } from "gatsby"

const CTA = (props) => {
    let classNames = 'cta';
    if (props.classNames) {
        classNames = `cta ${props.classNames}`;
    }

    let to = '/';
    if (props.to) {
        to = `${props.to}`;
    }

    let classNamesForText = 'cta__text';
    if (props.hideText) {
        classNamesForText = `cta__text is--invisible`;
    }

    if (to.startsWith('http')) {
        return (
            <a href={to} target="_blank" rel="noopener noreferrer" className={classNames} title={props.title}>
                <span className={classNamesForText}>{props.text}</span>
            </a>
        )
    } else {
        return (
            <Link to={to} className={classNames} title={props.title}>
                <span className={classNamesForText}>{props.text}</span>
            </Link >
        )
    }
}

export default CTA