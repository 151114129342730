import React, { useState } from 'react'
import Logo from './logo';
import { Link } from "gatsby"
import { Location } from '@reach/router'
import ScrollPositionIndicator from './ScrollPositionIndicator';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import HAMBURGER from '../../icons/hamburger.svg'

const navigationItems = [
    {
        title: 'Posts',
        slug: '/posts'
    },
    {
        title: 'References',
        slug: '/references'
    },
    {
        title: 'Case studies',
        slug: '/case-studies'
    },
    {
        title: 'Profile',
        slug: '/profile'
    },
]

const navigation = []
const baseClassName = 'pageHeader__link'
const activeClassName = 'pageHeader__link--is-active'

/**
 * activeClassName prop provided by reach router
 */
navigationItems.map((item, index) => {
    const { title, slug } = item

    navigation.push(
        <li className="pageHeader__navItem" key={`${index}_${slug.substring(1)}`}>
            <Link activeClassName={activeClassName} className={[baseClassName, `${baseClassName}_${title.toLocaleLowerCase()}`].join(" ").trim()} to={slug}>{title}</Link>
        </li>
    )
})

const PageHeader = (props) => {
    const [navToggled, setNavToggled] = useState(false)
    const { headerText } = props

    const handleHamburgerClick = () => {
        setNavToggled(!navToggled)
    }

    return (
        <header className="pageHeader" id="top">
            <div className="pageHeader__top">
                <Logo placement="pageHeader" />
                <p className="pageHeader__message">{headerText ? headerText : '[...]'}</p>
            </div>

            <Location>
                {({ location }) => {
                    return (
                        <>
                            <nav className="pageHeader__nav">
                                <ul className={navToggled ? 'pageHeader__navItems--open' : 'pageHeader__navItems'}>
                                    {navigation ? navigation : <li>No entries</li>}
                                </ul>
                            </nav>
                            <button
                                type="button"
                                className={navToggled ? 'pageHeader__hamburger--open' : 'pageHeader__hamburger'}
                                title="Toggle navigation"
                                onClick={handleHamburgerClick}
                            >
                                <SVG src={HAMBURGER} />
                            </button>
                            <ScrollPositionIndicator />
                        </>
                    )
                }}
            </Location>
        </header>
    )
};

export default PageHeader